/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Image from 'components/Image';

const ImageStyled = styled(Image)`
    width: 100%;
`;

const NameInitialStyled = styled.span`
    ${({ theme }) => theme.typography.hero};
    color: ${({ theme }) => theme.palette.accent};

    width: ${({ theme }) => theme.spacing[8]};
    height: ${({ theme }) => theme.spacing[8]};

    display: flex;
    align-items: center;
    justify-content: center;

    border: 2px solid ${({ theme }) => theme.palette.grey[100]};
    border-radius: ${({ theme }) => theme.spacing[4]};

    margin-right: ${({ theme }) => theme.spacing[2]};
`;

export { ImageStyled, NameInitialStyled };
