import styled from 'styled-components';

const DefaultImageStyled = styled.img`
    width: 100%;
    height: 100%;
`;

const DefaultImageWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export { DefaultImageStyled, DefaultImageWrapper };
