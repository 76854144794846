/* eslint-disable react/sort-comp */
import React, { Component } from 'react';

import { FILM_RATIO, FILM_RATIO_SMALL } from 'config/images';
import { addEventListener, debounce } from 'utils/funcs';
import { getCloudinaryImageUrl } from 'utils/integrations/cloudinary';
import { useInView } from 'react-intersection-observer';
import environment from '../../environment';

import { ImageState, TransformationSize, Transformations } from './Image.constants';
import { DefaultImageStyled, DefaultImageWrapper } from './Image.style';

class ImageComponent extends Component {
    static defaultProps = {
        transformation: FILM_RATIO.name,
    };

    ref;

    placeholderUrl;

    resizeListener;

    constructor(props) {
        super(props);

        const { imageId, transformation, customTransform } = props;

        this.placeholderUrl = props.placeholderUrl || environment.vars.listings.defaultImage;
        const initialImageUrl = imageId
            ? getCloudinaryImageUrl(imageId, transformation, customTransform)
            : this.placeholderUrl;

        this.state = {
            src: initialImageUrl,
            imageState: ImageState.LOADING,
            transformationData: {
                width: 0,
                transformation,
            },
        };
    }

    componentDidMount() {
        const { imageId } = this.props;

        // Return empty callback
        if (!imageId) return;

        // Resize Event Listeners
        this.resizeListener = addEventListener(
            window,
            'resize',
            debounce(() => {
                this.onResize();
            }, 1000)
        );

        this.updateTransformation();
    }

    componentWillUnmount() {
        if (this.resizeListener) {
            this.resizeListener.remove();
        }
    }

    // Load image
    loadImage(nextTransformation) {
        const { imageId, customTransform } = this.props;
        const { transformationData } = this.state;
        // Return empty callback
        if (!imageId) return;

        const imageUrl = getCloudinaryImageUrl(
            imageId,
            nextTransformation || transformationData.transformation,
            customTransform
        );

        /**
         * This is the flow until we add support for hashed blurred pixels
         *
         * - We attempt to load the image "in memory" using a new instance of Image.
         * - We respond to events on this instance of the Image.
         * - We update react state with the correct src
         * - React then re-renders and 'causes the image to load.
         *
         * The first load is needed to fast load/render images on first load ssr then because
         * onError, onLoad are not called on ssr we need the in-memory one is to fallback
         * if image failed to load initially (which we don't know)
         */
        const img = new Image();
        img.onload = () => this.imageOnLoad(img.src);
        img.onerror = () => this.imageOnError();
        img.src = imageUrl;
    }

    /**
     * Image loaded
     */
    imageOnLoad(src) {
        this.setState({
            src,
            imageState: ImageState.LOADED,
        });
    }

    /**
     * Image error
     */
    imageOnError() {
        this.setState({
            src: this.placeholderUrl,
            imageState: ImageState.ERROR,
        });
    }

    switchTransformations(nextTransformation, width) {
        this.setState(
            {
                transformationData: {
                    transformation: nextTransformation,
                    width,
                },
            },
            () => {
                this.loadImage(nextTransformation);
            }
        );
    }

    /**
     * This method attempts to find the closest transformation to the current elementWidth
     * It prefers smaller transformations over larger.
     */
    getNextTransformSize(width) {
        const { transformation } = this.props;

        const sizes = Transformations[transformation];

        if (typeof sizes === 'undefined') {
            return {
                transformation: `${transformation}`,
            };
        }
        /**
         * This method attempts to find the closest transformation to the current elementWidth
         */
        if (width < sizes.small) {
            return {
                transformation: `${transformation}_${TransformationSize.SMALL}`,
                width,
            };
        }
        if (width < sizes.medium) {
            // The transform for medium (or regular) size images is
            // just the transform name without anything appended
            return { transformation, width };
        }

        return {
            transformation: `${transformation}_${TransformationSize.BIG}`,
            width,
        };
    }

    updateTransformation() {
        if (!this.ref) return;
        const { width: elementWidth } = this.ref.getBoundingClientRect();
        const { transformationData } = this.state;
        if (elementWidth > transformationData.width) {
            const { transformation, width } = this.getNextTransformSize(elementWidth);
            this.switchTransformations(transformation, width);
        }
    }

    onResize() {
        const { imageState } = this.state;
        if (imageState === ImageState.LOADED) {
            this.updateTransformation();
        }
    }

    render() {
        const { className, alt, placeholderAlt } = this.props;
        const { imageState, src } = this.state;
        const fallbackAlt = placeholderAlt || alt;
        const imageAlt = imageState === ImageState.LOADED ? alt : fallbackAlt;

        return (
            <img
                ref={(innerRef) => {
                    if (innerRef) this.ref = innerRef;
                }}
                className={className}
                alt={imageAlt}
                src={src}
            />
        );
    }
}

const LazyImage = (props) => {
    const {
        className,
        style,
        imageId,
        transformation,
        alt,
        placeholderUrl: placeholder,
        customTransform = '',
    } = props;
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const placeholderUrl = placeholder || environment.vars.listings.defaultImage;
    const defaultImageUrl = imageId
        ? getCloudinaryImageUrl(imageId, transformation || FILM_RATIO_SMALL.name, customTransform)
        : placeholderUrl;

    return inView ? (
        <ImageComponent {...props} />
    ) : (
        <DefaultImageWrapper ref={ref}>
            <noscript>
                <DefaultImageStyled
                    src={defaultImageUrl}
                    alt={alt}
                    className={className}
                    style={style}
                />
            </noscript>
        </DefaultImageWrapper>
    );
};

export { LazyImage as default, ImageComponent };
