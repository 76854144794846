import {
    SQUARE,
    SQUARE_SMALL,
    SQUARE_BIG,
    FILM_RATIO,
    FILM_RATIO_SMALL,
    FILM_RATIO_BIG,
    ORIGINAL_RATIO,
    ORIGINAL_RATIO_SMALL,
    ORIGINAL_RATIO_BIG,
} from 'config/images';

const ImageState = {
    IDLE: 'idle',
    LOADING: 'loading',
    LOADED: 'loaded',
    ERROR: 'error',
    SERVER: 'server',
};

/*
 * This describes what is appended to a transformation in order to
 * get a different sized version.
 * e.g film_ratio_small, film_ratio, film_ratio_big
 * DEFAULT is left intentionally blank as we do not append anything to the default size
 */
const TransformationSize = {
    SMALL: 'small',
    DEFAULT: '',
    BIG: 'big',
};

const Transformations = {
    film_ratio: {
        small: FILM_RATIO_SMALL.width,
        medium: FILM_RATIO.width,
        big: FILM_RATIO_BIG.width,
    },
    original_ratio: {
        small: ORIGINAL_RATIO_SMALL.width,
        medium: ORIGINAL_RATIO.width,
        big: ORIGINAL_RATIO_BIG.width,
    },
    square: {
        small: SQUARE_SMALL.width,
        medium: SQUARE.width,
        big: SQUARE_BIG.width,
    },
};

export { ImageState, TransformationSize, Transformations };
