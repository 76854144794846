import React from 'react';
import Avatar from 'components/Avatar';
import { ImageStyled, NameInitialStyled } from './UserAvatar.style';
import environment from '../../environment';

const UserAvatar = ({ className, publicId, letter }) => {
    if (letter) {
        return <NameInitialStyled>{letter}</NameInitialStyled>;
    }

    return (
        <Avatar className={className}>
            <ImageStyled
                key={publicId}
                imageId={publicId}
                transformation="square"
                alt="avatar"
                placeholderUrl={environment.vars.profiles.defaultImage}
                placeholderAlt="avatar"
            />
        </Avatar>
    );
};

export default UserAvatar;
